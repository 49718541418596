




































































import Vue from 'vue'
import Component from 'vue-class-component'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { Action } from 'vuex-class'
import { GET_JOB_ADS } from '@/store/jobAd/actions'
import { JobAdType } from '@/store/jobAd/types'
import { SortingInfo } from '@/store/commonFormInput/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import Enums from '@/common/Enums'
import { localFormat } from '@/common/datePicker'

@Component({
  components: { LoadingSpinner, SearchResult, ResultsPage }
})
export default class JobAdList extends Vue {
  @Action(GET_JOB_ADS)
  getAllJobAds: (getOwn: boolean) => Promise<Array<JobAdType>>

  isLoading = false
  showErrorMessage = false
  errorMessage = ''

  jobAds: JobAdType[] = []

  maxPerColumn = 1

  get sortable (): SortingInfo[] {
    return [
      { key: 'title', label: this.$i18n.t('job-ad.title').toString() }
    ]
  }

  get isOwnJobAd (): boolean {
    return hasRight(Rights.JOB_AD_OWN_WRITE)
  }

  parseDate (date: string): string {
    return localFormat(date, this.$i18n.locale)
  }

  loadJobAds (): void {
    this.isLoading = true
    this.getAllJobAds(this.isOwnJobAd).then(data => {
      this.jobAds = data.filter(jobAd => {
        return jobAd.status === Enums.JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED || jobAd.status === Enums.JOB_AD_STATUS_PUBLISHED
      })
    }).catch(error => {
      if (error.status !== 409) {
        this.errorMessage = error
        this.showErrorMessage = true
      }
    }).finally(() => {
      this.isLoading = false
    })
  }

  mounted (): void {
    this.loadJobAds()
  }
}
